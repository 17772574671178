.root-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 140px;
  margin-top: 32px;
}

.profile-image img {
  width: 130px;
  border-radius: 50%;
  border: 2.7px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 13px 27px rgba(31, 32, 65, 0.1);
}

.profile-information {
  width: 500px;
}

.profile-title-name {
  text-align: center;
  margin-top: 22px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  color: #1f2041;
}

.profile-desk {
  display: flex;
}

.profile-row {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-row:first-child {
  margin-right: 100px;
}

.profile-items {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-items p {
  margin: 0;
}

.profile-items i {
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .profile-row:first-child {
    margin-right: 40px;
  }

  .profile-information {
    width: 432px;
  }
}

@media screen and (max-width: 450px) {
  .profile-information {
    width: 100%;
  }
  .profile-desk {
    display: flex;
    flex-direction: column;
  }

  .profile-items {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-row:first-child {
    margin-right: 0;
  }
}