.project-section-standupers {
  width: 256px;
  flex-direction: column;
}

.project-section-standupers-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0062ff;
}

.project-lists-users {
  padding-top: 19px;
}

.list-information-schedule {
  margin-top: 20px;
  span {
    color: #a0a4a8;
    font-size: 13px;
  }
  table {
    margin: 20px;
  }
  thead {
    td {
      padding-right: 50px;
      font-weight: 900;
    }
  }
}
.list__limit {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-item {
    display: flex;
    flex-direction: column;
    label {
      font-size: 12px;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    label {
      font-size: 12px;
    }
    input[type="checkbox"] {
      bottom: 10px;
      position: absolute;
    }
  }
}

.edit-root {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  &-btn {
    padding: 5px 22px;
    font-weight: bold;
    background: #0062ff;
    border: none;
    color: #ffffff;
  }

  .check-box__days-off {
    display: flex;
    align-items: center;
    input[type="checkbox"] {
      position: static !important;
      left: 0 !important;
    }
  }
}

.row-edit-form {
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 220px;
  }
}
