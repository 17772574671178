.root-section-comedian {
  margin-top: 44px;
}

.row-comedian {
  display: flex;
}

.item-comedian-title {
  margin-top: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 66px;
  letter-spacing: 1px;
  color: #0062ff;
}

.item-comedian-subtitle {
  width: 80%;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #505f98;
}

.button-comedian {
  margin-top: 30px;
  display: flex;
}

.button-update {
  height: 36px;
  padding: 5px 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #0062ff;
}

.button-update:hover {
  text-decoration: none;
  color: #ffffff;
}

.button-docs {
  height: 36px;
  margin-left: 30px;
  padding: 5px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #091133;
  border: 2px solid #000000;
}

.button-docs:hover {
  text-decoration: none;
  color: #091133;
}

.root-section-capability {
  margin-top: 102px;
  background: #0062ff;
}

.row-capability {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-capability-title {
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 1px;
  color: #ffffff;
}

.item-capability-card {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.item-capability-card img {
  height: 48px;
}

.item-capability-text {
  margin-top: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.navigation-link {
  margin-top: 54px;
  text-align: center;
  margin-bottom: 41px;
}

.capability-link {
  margin-right: 11px;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #ffffff;
}

.capability-link:hover {
  color: #ffffff;
}

.capability-link::before {
  content: "";
  width: 12px;
  height: 7px;
  display: flex;
  position: absolute;
  top: 4px;
  right: -20px;
  background-image: url("../../assets/images/expand_more-white.svg");
  background-repeat: no-repeat;
  transform: rotate(270deg);
  background-size: 10px;
}

.root-section-user-docs {
  margin-top: 36px;
  margin-bottom: 192px;
}

.item-user-docs-title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 1px;
  color: #0062ff;
}

.row-user-docs {
  margin-top: 29px;
  display: flex;
}

.item-user-docs-card:first-child {
  margin: 0;
}

.item-user-docs-card {
  margin-left: 20px;
}

.user-docs-subtitle {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #505f98;
}

.user-docs-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #091133;
}

@media screen and (max-width: 992px) {
  .root-section-comedian {
    margin-top: 0;
  }

  .button-update {
    width: 190px;
  }

  .button-docs {
    width: 192px;
  }

  .item-comedian {
    img {
      width: 300px;
      margin-top: 100px;
    }
  }
}

@media screen and (max-width: 767px) {
  .row-comedian {
    flex-direction: column;
  }

  .item-comedian-subtitle {
    width: 100%;
  }

  .item-capability-card {
    margin: 10px 25px;
    width: 143px;
    height: 130px;
  }

  .row-capability {
    justify-content: center;
    flex-wrap: wrap;
  }

  .item-capability-title {
    text-align: center;
  }

  .row-user-docs {
    flex-direction: column;
  }

  .item-user-docs-card {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }

  .item-user-docs-title {
    text-align: center;
  }

  .row-comedian {
    .item-comedian:last-child {
      margin: 0 auto;
    }
  }
  .item-comedian {
    img {
      width: 70%;
      margin-top: 50px;
      margin-left: 15%;
    }
  }
  .root-section-user-docs {
    margin-top: 36px;
    margin-bottom: 20px;
  }

  .navigation-link {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 450px) {
  .row-comedian {
    flex-direction: column;
  }

  .item-comedian-title {
    margin-top: 20px;
  }

  .button-update {
    width: calc(224px /2);
    padding: 5px 8px;
    font-size: 14px;
  }

  .button-docs {
    width: calc(260px /2);
    padding: 5px 7px;
    margin-left: 25px;
    font-size: 14px;
  }

  .item-comedian {
    img {
      width: 73%;
      margin-top: 20px;
    }
  }

  .root-section-capability {
    margin-top: 60px;
    background: #0062ff;
  }

  .item-capability-title {
    text-align: center;
  }

  .item-capability-card {
    margin-top: 25px;
  }

  .row-capability {
    flex-direction: column;
  }

  .item-user-docs-title {
    text-align: center;
  }

  .row-user-docs {
    flex-direction: column;
  }

  .item-user-docs-card {
    margin-left: 0;
    margin-top: 40px;
    text-align: center;
  }

  .root-section-user-docs {
    margin-top: 36px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 380px) {
  .row-comedian {
    flex-direction: column;
  }

  .item-comedian-title {
    margin-top: 20px;
  }

  .button-update {
    width: calc(224px /2);
    padding: 5px 8px;
    font-size: 14px;
  }

  .button-docs {
    width: calc(260px /2);
    padding: 5px 7px;
    font-size: 14px;
  }

  .item-comedian {
    img {
      width: 72%;
      margin-top: 20px;
    }
  }

  .root-section-capability {
    margin-top: 60px;
    background: #0062ff;
  }

  .item-capability-title {
    text-align: center;
  }

  .item-capability-card {
    margin-top: 25px;
  }

  .row-capability {
    flex-direction: column;
  }

  .item-user-docs-title {
    text-align: center;
  }

  .row-user-docs {
    flex-direction: column;
  }

  .item-user-docs-card {
    margin-left: 0;
    margin-top: 40px;
    text-align: center;
  }

  .root-section-user-docs {
    margin-top: 36px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 320px) {
  .row-comedian {
    flex-direction: column;
  }

  .item-comedian-title {
    margin-top: 20px;
  }

  .button-update {
    width: calc(340px /2);
    padding: 5px 8px;
    font-size: 14px;
  }

  .button-docs {
    width: calc(340px /2);
    padding: 5px 7px;
    font-size: 14px;
  }

  .item-comedian {
    img {
      width: 72%;
      margin-top: 20px;
    }
  }

  .root-section-capability {
    margin-top: 60px;
    background: #0062ff;
  }

  .item-capability-title {
    text-align: center;
  }

  .item-capability-card {
    margin-top: 25px;
  }

  .row-capability {
    flex-direction: column;
  }

  .item-user-docs-title {
    text-align: center;
  }

  .row-user-docs {
    flex-direction: column;
  }

  .item-user-docs-card {
    margin-left: 0;
    margin-top: 40px;
    text-align: center;
  }

  .root-section-user-docs {
    margin-top: 36px;
    margin-bottom: 0;
  }

  .navigation-link {
    margin-bottom: 60px;
  }
}
