.header-root {
  width: 100% !important;
  height: 67px;
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
  display: flex;
}

.header-root .nav-link {
  padding-top: 1px;
  margin: 0 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: rgba(31, 32, 65, 0.5) !important;
}

.root-custom-line-header {
  margin-right: 22px;
  position: relative;
}

.custom-line-header {
  height: 29px;
  width: 1px;
  top: -32px;
  position: absolute;
  background: rgba(31, 32, 65, 0.1);
}

.nav-link.active {
  font-weight: bold;
  color: #000000 !important;
}

.nav-link.first-blue {
  color: #0062ff !important;
}

.navbar-nav {
  align-items: center;
}

.avatar {
  margin: 0 12px;
  border-radius: 50%;
}

.navbar-toggler {
  background-color: #0062ff !important;
}

@media screen and (max-width: 1199px) {
  .header-root .nav-link {
    margin: 0 5px;
  }
}

@media screen and (max-width: 992px) {
  .navbar-expand-md .navbar-collapse {
    margin-top: 5px;
  }

  .root-custom-line-header {
    display: none;
  }

  .avatar {
    display: none;
  }

  .profile-link {
    margin-top: 0 !important;
  }

  .header-root .nav-link {
    padding-top: 1px;
    margin: 0 2px;
    font-size: 11px;
  }

  .profile-link::before {
    top: 6px;
    left: 2px;
  }

  .flag-nav {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .root-custom-line-header {
    display: none;
  }

  .navbar-nav {
    align-items: flex-end;
  }

  .header-root .nav-link {
    padding-top: 1px;
    margin: 0 10px;
    display: flex;
    flex-direction: column-reverse;
    font-size: 14px;
    text-align: end;
  }

  .profile-link::before {
    top: 8px;
  }

  .avatar {
    display: none;
  }

  .profile-link {
    margin-right: 12px !important;
  }

  .flag-nav {
    margin-right: 23px;
  }

  .dropdown-menu {
    min-width: 8rem !important;
    margin-bottom: 10px !important;
  }

  .navbar-collapse {
    margin-top: 18px;
    padding-bottom: 18px;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
    z-index: 100;
  }

  .nav-item {
    margin-right: 10px;
  }

  .navbar-expand-md .navbar-collapse {
    margin-top: 18px;
  }
}