.modal {
  &-cancel-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    font-size: 14px;
    background: transparent;
    outline: none;
  }
}

.custom-modal-title {
  margin-top: 15px;
}

.modal-btn-group {
  position: absolute;
  top: 15px;
  right: 50px;
  i {
    margin: 0 5px;
    &:first-child {
      color: #0062ff;
    }
    &:last-child {
      color: #ff0000;
    }
  }
}

.modal-cancel-btn {
  position: absolute;
  right: 10px;
}

.custom-modal-title {
  margin-top: 20px;
}

.modal-btn-group {
  display: flex;
  margin: 10px 0;
  justify-content: flex-end;
}