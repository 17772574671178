.custom-from {
  display: flex;
}

.analytics-form {
  width: 480px;
  padding: 35px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.42);
}

.bg-light .active {
  color: #cccccc !important;
}

.btn-analytics {
  display: flex;
  justify-content: center;
}

.btn-analytics button {
  width: 100%;
  margin-top: 30px;
}

.title-analytics {
  display: flex;
  justify-content: center;
  padding: 30px 0 10px 0;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.analytics-row {
  margin-top: 40px;
}

.analytics-row .card {
  margin: 0 !important;
  border: none;
  box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.42);
}

.item-center-custom {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px){
  .analytics-row {
    flex-direction: column;
  }

  .analytics-form {
    margin-top: 30px;
  }

  .title-analytics {
    font-size: 25px;
  }
}
