.popover-close-btn {
  width: 30px;
  height: 30px;
  padding: 4px;
  border: none;
  border-radius: 5px;
  background: url("../../../assets/images/expand_more-white.svg");
  background-color: #0062FF;
  background-position: 9px;
  background-repeat: no-repeat;
  color: #ffffff;
  outline: none !important;
  transform: rotate(180deg);
}

.root-popover-sprint .popover-btn {
  text-decoration: underline;
  cursor: pointer;
}