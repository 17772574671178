@import "./_Standupers";
@import "./_Stanups";
@import "./_Question";

.root-project-body-row {
  display: flex;
  justify-content: space-between;
}

.project-border {
  min-height: 550px;
  max-height: 550px;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: relative;
  z-index: 1;
  p {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.root-project {
  margin-bottom: 20px;
  box-shadow: 0 2px 4px -5px rgba(0, 0, 0, 0.75);
  background: #0062ff;
}

.root-project-title {
  margin-top: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 1px;
  color: #ffffff;
}

.root-project-items {
  display: flex;
}

.root-project-items:first-child {
  margin-top: 12px;
}

.root-project-items:last-child {
  margin-top: 27px;
  margin-bottom: 29px;
  justify-content: space-between;
  align-items: center;
}

.root-project-items:last-child .root-project-item {
  width: 77%;
}

.root-project-items:first-child .root-project-item {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(231, 231, 231, 0.3);
}

.root-project-items:first-child .root-project-item:first-child {
  width: 160px;
}
.root-project-items:first-child .root-project-item:nth-child(2) {
  width: 185px;
  padding: 0 33px;
}
.root-project-items:first-child .root-project-item:nth-child(3) {
  width: 184px;
  padding: 0 41px;
}
.root-project-items:first-child .root-project-item:nth-child(4) {
  width: 164px;
  padding: 0 40px;
}
.root-project-items:first-child .root-project-item:nth-child(5) {
  width: 202px;
  padding: 0 26px;
}
.root-project-items:first-child .root-project-item:nth-child(6) {
  width: 260px;
  padding: 0 0 0 15px;
}

.root-project-items:first-child .root-project-item:last-child {
  border-right: none;
}

.root-project-item label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.7;
}

.area-project label{
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.7;
}

.root-project-item input {
  height: 38px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
}
.root-project-item #submission_days input{
  height: auto;
}

.root-project-item {

  i {
    color: aliceblue;
    vertical-align: bottom;
    z-index: 100;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
}


.root-project-item .project-message {
  margin-left: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.root-project .row {
  margin: 10px 0;
}

.root-project-items .btn-end {
  width: auto;

}

.btn-end .button-project-open {
  margin: 0;
  padding: 8px 44px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0062ff;
  background: #ffffff;
}

.root-project-items:last-child.customize-project-item {
  margin-top: 38px;
  margin-bottom: 32px;
  align-items: flex-end;
}

.root-project-items .btn-end button {
  margin: 0 6px;
}

.customize-project-item textarea {
  height: 81px !important;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
}

.area-project {
  width: 60%;
}

.btn-start {
  width: 39%;
}

.btn-start button {
  margin: 0 5px;
}

.btn-start button:first-child {
  padding: 15px 82px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0062ff;
  background: #ffffff;
  border-radius: 0;
  border: none;
}

.btn-start button:last-child {
  margin-left: 44px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  background: transparent;
}

.days-form {
  display: flex;
  align-items: flex-start;
  .open-days {
    cursor: pointer;
    text-decoration: underline;
  }
  .close-days {
    cursor: pointer;
    padding: 0 5px;
    border: 1px solid;
  }
}

.root-text-edit {
  .text-edit {
    padding: 7px 27px 7px 10px;
    display: flex;
    position: relative;
    font-size: 13px;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      right: 0;
      background: url("../../assets/images/expand_more-white.svg") 0 0 no-repeat;
      width: 20px;
      height: 15px;
    }
  }
}

.root-close-menu {
  display: flex;
  justify-content: flex-end;
  &-btn {
    margin-top: 5px;
    margin-right: 5px;
    color: #000 !important;
  }
}

@media screen and (max-width: 1199px) {
  .btn-start button:first-child {
    padding: 15px 50px;
  }

  .root-project-items:first-child .root-project-item:nth-child(5) {
    width: 250px;
    padding: 0 26px;
  }

  //body project component
  .project-section-standups {
    width: 660px;
    flex-direction: column;
  }

  .project-section-standupers-title {
    font-size: 18px;
  }

  .list-image-standupers {
    width: 24px;
  }

  .project-section-question-items span {
    margin: 5px 5px;
  }

  .project-section-question-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .root-project-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .root-project-items:first-child {
    .root-project-item {
      width: 33% !important;
      padding: 0 !important;
      margin-bottom: 25px;
      border-right: none;
      text-align: center;
    }
  }

  .root-project-items:last-child .root-project-item {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root-project-item .project-message {
    margin-left: 0;
    line-height: 15px;
  }

  .root-project-items {
    .btn-end {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .btn-start {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root-project-items:last-child.customize-project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customize-project-item textarea {
    width: 100%;
  }

  .area-project {
    width: 100%;
    margin-bottom: 30px;
  }

  .btn-start button:last-child {
    margin-left: 0;
  }

  .root-project-item input {
    width: 225px;
  }

  .days-form {
    display: flex;
    justify-content: center;
  }

  //body project component
  .project-section-standups {
    width: 420px;
    flex-direction: column;
  }

  .project-section-standupers-title {
    font-size: 18px;
  }

  .list-image-standupers {
    width: 24px;
  }

  .project-section-question-items-desk {
    width: 100%;
    margin-bottom: 50px;
  }

  .project-section-question-items span {
    margin: 5px 5px;
  }

  .project-section-question-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .root-project-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .root-project-items:first-child {
    .root-project-item {
      width: 33% !important;
      padding: 0 !important;
      margin-bottom: 25px;
      border-right: none;
    }
  }

  .root-project-items:last-child .root-project-item {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root-project-item .project-message {
    margin-left: 0;
    line-height: 15px;
  }

  .root-project-items {
    .btn-end {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .btn-start {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root-project-items:last-child.customize-project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customize-project-item textarea {
    width: 100%;
  }

  .area-project {
    width: 100%;
    margin-bottom: 30px;
  }

  .btn-start button:last-child {
    margin-left: 0;
  }

  .root-project-item input {
    width: 150px;
  }

  //body project component
  .root-project-body-row {
    flex-direction: column;
  }

  .project-section-standupers {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .project-section-standups {
    width: 100%;
    flex-direction: column;
  }

  .project-border {
    padding: 0 15px;
    min-height: 100px;
  }

  .project-section-standupers-title {
    font-size: 18px;
  }

  .list-image-standupers {
    width: 24px;
  }

  .project-section-question-items-desk {
    width: 100%;
    margin-bottom: 45px;
  }

  .project-section-question-items span {
    margin: 5px 5px;
  }

  .project-section-question-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 450px) {
  .root-project-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .root-project-items:first-child {
    .root-project-item {
      width: 49% !important;
      padding: 0 !important;
      margin-bottom: 25px;
      border-right: none;
    }
  }

  .root-project-items:last-child .root-project-item {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root-project-item .project-message {
    margin-left: 0;
    line-height: 15px;
  }

  .root-project-items {
    .btn-end {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .btn-start {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root-project-items:last-child.customize-project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customize-project-item textarea {
    width: 100%;
  }

  .area-project {
    width: 100%;
    margin-bottom: 30px;
  }

  .btn-start button:last-child {
    margin-left: 0;
  }

  .root-project-item input {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .root-project-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .root-project-items:first-child {
    .root-project-item {
      width: 49% !important;
      padding: 0 !important;
      margin-bottom: 25px;
      border-right: none;
    }
  }

  .root-project-items:last-child .root-project-item {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root-project-item .project-message {
    margin-left: 0;
    line-height: 15px;
  }

  .root-project-items {
    .btn-end {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .btn-start {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root-project-items:last-child.customize-project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customize-project-item textarea {
    width: 100%;
  }

  .area-project {
    width: 100%;
    margin-bottom: 30px;
  }

  .btn-start button:last-child {
    margin-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .root-project-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .root-project-items:first-child {
    .root-project-item {
      width: 49% !important;
      padding: 0 !important;
      margin-bottom: 25px;
      border-right: none;
    }
  }

  .root-project-items:last-child .root-project-item {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .root-project-item .project-message {
    margin-left: 0;
    line-height: 15px;
  }

  .root-project-items {
    .btn-end {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .btn-start {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root-project-items:last-child.customize-project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customize-project-item textarea {
    width: 100%;
  }

  .area-project {
    width: 100%;
    margin-bottom: 30px;
  }

  .btn-start button:last-child {
    margin-left: 0;
  }
}
