.docs-title-background-color {
  background: #F2F2F2;
}

.docs-title-background-vinyls {
  background-image: url("../../assets/images/Shape.png");
  background-size: 450px;
  background-repeat: no-repeat;
  background-position: 81% 15px;
}

.docs-title-row {
  padding-top: 47px;
}

.logo-text {
  display: flex;
  justify-content: center;
}

.docs-title {
  color: #0062FF;
}

.docs-subtitle {
  margin-top: 21px;
  margin-bottom: 87px;
  font-size: 18px;
  line-height: 30px;
  color: #505F98;
}

.album {
  background: #FFFFFF;
}

.album-docs-title {
  padding-top: 47px;
  font-weight: bold;
  color: #0062FF;
}

.album-docs-title-fn {
  padding-top: 76px;
  font-weight: bold;
  color: #0062FF;
}

.album-docs-position .custom-card {
  padding: 0 !important;
  margin-top: 42px;
  margin-bottom: 45px;
}

.album-docs-fn .custom-card {
  padding: 0 !important;
  margin-left: 5px;
  margin-top: 39px;
}

.custom-card-title {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.7899px;
  font-weight: bold;
  color: #000000;
}

.custom-card-subtitle {
  margin-top: 9px;
  letter-spacing: 1.2333px;
  font-size: 18px;
  line-height: 30px;
  color: #505F98;
}

.album-docs-fn .custom-card-subtitle {
  margin: 0;
  letter-spacing: 1.2333px;
  font-size: 18px;
  line-height: 30px;
  color: #505F98;
}

.custom-row {
  img {
    margin: 10px 0;
  }

  h3 {
    margin-top: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #0062ff;
  }

  .blog-main {
    margin-bottom: 30px;
  }

  .blog-post-title {
    margin-bottom: 1.5rem;
  }

  .docs-image {
    width: 100%;
  }

  .docs-image-default{
    width: 70%;
  }

  h5 {
    span {
      padding: 2px 7px;
      border-radius: 3px;
      font-weight: 300;
      font-size: 16px;
      background: #0062ff;
      color: #ffffff;
    }
  }

  .docs-image-command {
    width: 100%;
    padding: 20px 0;
  }

  p {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .blog-post {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 35px;
    }
  }

  li {
    margin: 10px 0;
  }

  .navigation-desk {
    display: flex;
    flex-direction: column;
    a {
      margin: 10px 0;
      padding-bottom: 5px;
      display: inline-block;
      color: #000000;
      line-height: 1;
      text-decoration: none;
      cursor: pointer;
      position: relative;
    }

    a:after {
      margin-top: 3px;
      display: block;
      content: "";
      background-color: #0062ff;
      height: 3px;
      width: 0%;
      left: 50%;
      position: absolute;
      -webkit-transition: width .3s ease-in-out;
      -moz--transition: width .3s ease-in-out;
      transition: width .3s ease-in-out;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    a:hover:after,
    a:focus:after {
      width: 100%;
    }
  }

  .custom-blog-sidebar-root {
    h4 {
      font-weight: 600;
      color: #0062ff;
    }
  }
}

.root-burger-desk {
  position: relative;
}

.root-burger-desk-menu {
  display: none;
  position: fixed;
  right: 5%;
  bottom: 20px;
  z-index: 90;
}

.burger-btn {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 50%;
  background: #008a00;
  color: #ffffff;
  z-index: 50;
}

.custom-blog-sidebar-root {
  display: flex;
}

.root-burger-close {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .navigation-desk {
    background: #ffffff;
    text-align: end;
    padding: 15px;
    border-radius: 5px;
    div {
      margin: 5px 0;
      a {
        font-size: 14px;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .col-8 {
    max-width: 100% !important;
  }

  .col-7 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .root-burger-desk-menu {
    display: block;
  }

  .custom-blog-sidebar-root {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .col-8 {
    max-width: 100% !important;
  }

  .col-7 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .row {
    flex-direction: column-reverse;
  }

  img{
    width: 100%;
  }
}
