.project-section-standups {
  width: 870px;
  flex-direction: column;
}

.desk-standups-list {
  margin-top: 30px;
  max-height: 515px;
  overflow-y: scroll;
  .root-standups-list {
    display: flex;
  }
  &-loading {
    padding: 50px 20px;
    font-size: 20px;
    border-radius: 50%;
    position: absolute;
    top: 35%;
    left: 42%;
    color: #0062ff;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 100;
    opacity: 1;
  }
  &-error {
    color: #ff0000;
    font-size: 12px;
  }
}

