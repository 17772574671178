.guest_page {
  height: 100vh;
}
.card__guest_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background__guest_page {
  height: 100%;
}

.background__guest_page h3{
  padding-top: 20px;
}
.card_page {
  padding: 38px;
  margin-bottom: 90px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  margin-bottom: 20px;
}
