.tw {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  display: block;
  background: url("../../../assets/images/tw.svg") 0 0 no-repeat;
}

.fb {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  display: block;
  background: url("../../../assets/images/fb.svg") 0 0 no-repeat;
}

.in {
  width: 24px;
  height: 24px;
  display: block;
  background: url("../../../assets/images/in.svg") 0 0 no-repeat;
}

.footer-block {
  margin-top: 68px;
  background: #f2f2f2;
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05);
  z-index: 100;
}

.root-footer-card {
  padding-top: 35px;
  display: flex;

  .root-footer-img {
    margin-right: 30px;
  }
  .social-footer {
    display: flex;
  }
}

.root-footer-navbar {
  margin-top: 21px;
  margin-bottom: 36px;
  .navbar-nav {
    align-items: flex-start;
    flex-direction: row;
    .nav-item {
      margin-right: 40px;
      a {
        color: rgba(31, 32, 65, 0.5);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .root-footer-navbar .navbar-nav {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .root-footer-navbar .navbar-nav .nav-item {
    margin-right: 0;
  }

  .root-footer-card {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column-reverse;
  }

  .root-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .root-footer-card .social-footer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .root-footer-card .root-footer-img {
    margin-right: 0;
  }

  .root-footer-navbar .navbar-nav .nav-item a {
    font-size: 13px;
  }
}
