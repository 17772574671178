.error-page {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    margin: 10px 0;
    font-size: 50px;
    font-weight: 800;
    color: #0062ff;
  }

  &-subtitle {
    text-align: center;
  }

  &-uppercase {
    text-transform: uppercase;
    font-weight: 500;
  }

  &-btn {
    padding: 10px 20px;
    background: #0062ff;
    border-radius: 4px;
    color: #ffffff;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &-line {
    margin: 30px 0;
    width: 280px;
  }

  p:last-child {
    margin: 0;
    color: #8e8e8e;
  }
}