@mixin random-bgr(){
  background: rgba(random(255), random(255), random(255), 0.5);
}

.background-head {
  margin-top: 2px;
  padding-bottom: 12px;
  background: #0062ff;
}

.triggers-head {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.triggers-head h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 1px;
  color: #ffffff;
}

.root-triggers-btn {
  margin-top: 5px;
}

.triggers-btn {
  padding: 15px 95px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #0062ff;
  border: none;
  background: #ffffff;
}

.triggers-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.sprint-element-row .triggers-item input,
.sprint-element-row .triggers-item select {
  width: 220px;
}

.triggers-row:nth-child(2) {
  margin-top: 14px;
}

.triggers-item label {
  color: #ffffff;
}

.triggers-item .custom-input,
.triggers-item select {
  width: 261px;
  height: 56px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  color: #0062ff;
  box-sizing: border-box;
  border-radius: 3px;
}

.triggers-item .css-yk16xz-control {
  width: 261px;
  background: rgba(255, 255, 255, 0.7) !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.triggers-row:nth-child(2) .css-yk16xz-control {
  min-width: 560px;
}

.triggers-row:nth-child(2) .css-1pahdxg-control {
  min-width: 560px;
}

.triggers-items-btn {
  width: 562px;
  margin-top: 29px;
}

.triggers-items-btn .btn-triggers button {
  border: none;
}

.triggers-btn-create {
  margin-right: 54px;
  padding: 14px 93px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  background: #ffffff;
  color: #0062ff;
}

.triggers-btn-close {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  background: transparent;
}

.triggers-form {
  margin-bottom: 10px;
}

.custom-input::placeholder {
  color: #0062FF !important;
  opacity: 0.7;
}

/* Стили для списка триггеров */

.triggers-title {
  margin-top: 31px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0062ff;
}

.trigger-info-items {
  display: flex;
  position: relative;
}

.trigger-info {
  margin-top: 19px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.last-card-item {
  border: none !important;
}

.trigger-card-item-title {
  margin: 0;
  padding-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #8f8fa0;
}

.trigger-card-item-text {
  margin: 0;
  padding-top: 15px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.trigger-card-item {
  margin-right: 20px;
  width: 186px;
  border-right: 1px solid #8f8fa0;
}

.groupe-triggers-users {
  display: flex;
  margin-top: 8px;
}

.trigger-info-items .btn {
  position: absolute;
  right: 25px;
  top: 20px;
  font-size: 25px;
  color: red !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.desk-sprint {
  display: flex;
  flex-direction: column;
}

.name-item-user {
  padding: 0;
  margin: 0;
  margin-right: 5px;
}

.show-triggers {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  background: #cccccc;
  padding: 12px;
  width: 285px;
  top: 75px;
}

.change-color .name-item-user {
  padding: 4px;
  color: #000000;
  border-radius: 5px;
  $num-colors: 100;
  $base-color: #996b4d;
  @for $i from 0 to $num-colors {
    &:nth-child(#{$i}) {
      @include random-bgr();
    }
  }
}

.change-color .root-popover-body .name-item-user {
  $num-colors: 100;
  $base-color: #0065ff;
  @for $i from 0 to $num-colors {
    &:nth-child(#{$i}) {
      @include random-bgr();
    }
  }
}

.groupe-triggers-users .popover-btn {
  background: #0062ff;
  border-radius: 5px;
  padding: 4px;
  color: #ffffff;
  outline: none !important;
}

.groupe-triggers-users .root-popover {
  position: relative;
}

.root-popover-body {
  width: 312px;
  padding: 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  right: 0;
  background: #ffffff;
  z-index: 100;
}

.root-popover .name-item-user {
  margin-top: 5px;
}

.sprint-info-items .trigger-card-item {
  margin-right: 8px;
  width: 160px;
  border-right: 1px solid #8f8fa0;
}

.trigger-info-items .edit-sprint {
  color: #0062ff !important;
  right: 0;
}

.trigger-info-items .delete-sprint {
  right: 40px;
}

.groupe-sprint-users {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.root-popover-sprint {
  position: relative;
}

.root-popover-sprint .root-popover-body {
  width: 163px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  right: -35px;
  position: absolute;
  background: #ffffff;
  z-index: 100;
}

.desktop {
  display: flex;
}

.tablet {
  display: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1199px) {
  .sprint-info-items .trigger-card-item {
    width: 130px;
  }

  .groupe-triggers-users {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    width: 170px;
  }
}

@media screen and (max-width: 995px) {
  .desk-sprint {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .trigger-info-items {
    flex-direction: column;
  }

  .sprint-info-items .trigger-card-item {
    border-right: none;
  }

  .card-triggers {
    width: 280px;
  }

  .groupe-triggers-users {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    width: 170px;
  }

  .trigger-card-item {
    border-right: none;
  }
}

@media screen and (max-width: 767px) {
  .desktop {
    display: none;
  }

  .tablet {
    display: flex;
  }

  .mobile {
    display: none;
  }

  .triggers-head {
    flex-wrap: wrap;
  }

  .triggers-row {
    flex-wrap: wrap;
  }
  .triggers-btn {
    padding: 10px 25px;
    justify-content: space-between;
  }

  .sprint-element-row .triggers-item input {
    width: 200px;
  }

  .triggers-btn-create {
    margin-right: 30px;
    padding: 11px 40px;
  }

  .triggers-head h2 {
    font-size: 25px;
  }

  .triggers-items-btn {
    width: 100%;
  }

  .btn-triggers {
    display: flex;
    justify-content: center;
  }

  .triggers-btn-close {
    padding: 0 25px;
  }

  .root-triggers-btn {
    margin-top: 10px;
  }

  .triggers-row {
    &:first-child {
      justify-content: center;

      .triggers-item {
        margin: 0 5px;
      }
    }
  }

  .desk-sprint {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .trigger-info-items {
    flex-direction: column;
  }

  .sprint-info-items .trigger-card-item {
    border-right: none;
  }

  .custom-row {
    .triggers-item {
      &:nth-child(2) {
        .form-group {
          margin-right: 45px;
        }
      }
    }
  }

  .triggers-row:nth-child(2) {
    margin-top: 14px;
    justify-content: center;
  }

  .groupe-triggers-users {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    width: 170px;
  }

  .trigger-card-item {
    border-right: none;
  }
  .name-user {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .desktop {
    display: none;
  }

  .tablet {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .triggers-head {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .triggers-row {
    flex-wrap: wrap;
  }

  .sprint-element-row .triggers-item input {
    width: 280px;
  }

  .triggers-btn-create {
    margin-right: 30px;
    padding: 11px 40px;
  }

  .triggers-head h2 {
    font-size: 25px;
  }

  .triggers-items-btn {
    width: 100%;
  }

  .btn-triggers {
    display: flex;
    justify-content: center;
  }

  .triggers-btn-close {
    padding: 0 25px;
  }

  .triggers-item .custom-input,
  .triggers-item select {
    height: 54px;
  }

  .trigger-info-items {
    flex-direction: column;
  }

  .sprint-info-items .trigger-card-item {
    border-right: none;
  }

  .custom-row .triggers-item:nth-child(2) .form-group {
    margin-right: 0;
  }
}

@media screen and (max-width: 380px) {
  .desktop {
    display: none;
  }

  .tablet {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .triggers-head {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .triggers-row {
    flex-wrap: wrap;
  }

  .sprint-element-row .triggers-item input {
    width: 280px;
  }

  .triggers-btn-create {
    margin-right: 30px;
    padding: 11px 40px;
  }

  .triggers-head h2 {
    font-size: 25px;
  }

  .triggers-items-btn {
    width: 100%;
  }

  .btn-triggers {
    display: flex;
    justify-content: center;
  }

  .triggers-btn-close {
    padding: 0 32px;
  }

  .trigger-info-items {
    flex-direction: column;
  }

  .sprint-info-items .trigger-card-item {
    border-right: none;
  }

  .custom-row .triggers-item:nth-child(2) .form-group {
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {
  .desktop {
    display: none;
  }

  .tablet {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .triggers-head {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .triggers-row {
    flex-wrap: wrap;
  }

  .sprint-element-row .triggers-item input {
    width: 280px;
  }

  .triggers-btn-create {
    margin-right: 30px;
    padding: 11px 40px;
  }

  .triggers-head h2 {
    font-size: 25px;
    line-height: 38px;
  }

  .triggers-items-btn {
    width: 100%;
  }

  .btn-triggers {
    display: flex;
    justify-content: center;
  }

  .triggers-btn-close {
    padding: 0 12px;
  }

  .trigger-info-items {
    flex-direction: column;
  }

  .sprint-info-items .trigger-card-item {
    border-right: none;
  }

  .custom-row .triggers-item:nth-child(2) .form-group {
    margin-right: 0;
  }
}
