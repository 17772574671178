.preloader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader-spinner {
  width: 8em !important;
  height: 8em !important;
}

.preloader-text {
  margin-top: 20px;
  font-size: 35px;
}
