@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800&display=swap&subset=cyrillic-ext,latin-ext');
@media screen and (max-width: 600px) {
  .root-sub-menu {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .sub-menu {
    margin: 10px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  opacity: 0;
}

html {
  height: 100%;
}

input[type="checkbox"] {
  position: static;
  left: 0;
}

body {
  height: 100%;
  margin: 0;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif !important;
  background-color: #ffffff;
}



.modal-open {
  padding-right: 0 !important;
}

.error-input {
  box-shadow: 0 0 0 0.2rem rgba(255, 16, 26, 0.91) !important;
}

.profile-link {
  margin-top: 5.2px !important;
  margin-right: 6px !important;
  letter-spacing: -0.4px;
  padding-left: 19px !important;
  position: relative;
}
.profile-link::before {
  content: '';
  width: 12px;
  height: 8px;
  top: 11px;
  left: -2px;
  position: absolute;
  display: flex;
  background: url("../assets/images/expand_more.svg"), 0 0, no-repeat;
}

main {
  position: relative;
  display: flex !important;
  flex-direction: column;
  flex: 1 0;
}

main section {
  display: flex;
}

#root {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.project-section {
  padding: 10px 0;
}

.project-section .container {
  height: 300px;
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 9px -5px rgba(0, 0, 0, 0.75);
}

.disabled .container {
  height: auto;
}

.disabled .scroll-element {
  height: auto;
}

.card-footer {
  background-color: transparent !important;
}

.project-section .card {
  margin-top: 5px !important;
}

.dropdown-menu {
  margin-top: 10px !important;
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0 10px 20px rgba(31, 32, 65, 0.05) !important;
}

.dropdown-item {
  padding: .25rem 0.5rem !important;
  text-align: end !important;
}

.dropdown-item{
  background: transparent !important;
}

.nav-link {
  cursor: pointer;
}

.button__save {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn-end button {
  margin: 0 5px;
}

.btn-pagination button {
  margin: 0 5px;
}

.btn-pagination span {
  margin: 0 10px;
}

.btn-pagination {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-icons {
  cursor: pointer;
}

.btn-add {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 60px;
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 40px;
  margin-right: 100px;
  background: #cccccc;
  border-radius: 50%;
}

.lock_open {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-groups {
  display: flex;
  justify-content: center;
}

.eye {
  vertical-align: middle;
}

.validation-form {
  color: #ff0000;
  font-size: 9px;
}

.sub-menu {
  color: #1b1b1b !important;
}

.scroll-element .card:hover {
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.26);
}

.flag-nav {
  width: 30px;
  height: 27px;
  margin-top: -5px;
  cursor: pointer;
}

.blue-background {
  background: #0062ff;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1190px !important;
  }
}

@import "./_GuestPage";
@import "./_Documentation";
@import "./_ComedianSettings";
@import "./_Analytics";
@import "./_LangingPage";
@import "./_Profile";
@import "./_Project";
@import "./_Triggers_Sprint";
@import "./_UI";

