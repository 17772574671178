.root-standups {
  &-list {
    display: flex;
    align-items: center;
  }

  &-text{
    margin-top: 10px;
    span {
      white-space: pre-line;
    }
  }
}
.time_create_standup {
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #a0a4a8;
}