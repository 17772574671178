.root-list-standupers {
  height: 65px;
  margin-bottom: 10px;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.root-list-standupers:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.root-list-standupers:hover:before{
  left: 0;
  right: 0;
}

.list-standupers {
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list-information-stanupers {
  width: 140px;
  display: flex;
  flex-direction: column;
  .list-information-role{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #a0a4a8;
  }
}

.list-image-standupers{
  margin-right: 12px;
  border-radius: 50%;
}

.list-btn {
  width: 75px;
  height: 35px;
  position: relative;
  &-root {
    display: flex;
    position: absolute;
  }
  button {
    background: transparent;
    border: none;
  }
  .edit {
    color: #0062ff;
  }
  .delete {
    color: #ff0000;
  }
}
