.project-section-question {

  &-title {
    margin-top: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #0062ff;
  }

  &-items {
    min-height: 70px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    &-desk {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
    }
    span {
      padding: 5px 15px;
      margin: 5px 5px;
      border-radius: 5px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  &-root{
    position: absolute;
    right: 15px;
    bottom: 15px;
    &-btn {
      padding: 5px 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      background: #0062ff;
      border: none;
    }
  }
}

.name-user{
  &-title {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #8f8fa0;
  }
  &-list {
    display: flex;
    align-items: center;
    margin: 10px 0;
    img {
      border-radius: 50px;
      margin-right: 5px;
      width: 24px;
    }
  }
}

.question-element {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #8F8FA0;
  }
  &:last-child{
    margin-bottom: 20px;
    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
}

.edit-btn-group {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  button {
    padding: 8px;
    background: #0062ff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    color: #ffffff;
  }
}