.card {
  margin-top: 40px !important;
}

.col-4 .lists {
  height: 130px;
}
.col-4 .lists span {
  height: 100%;
  font-size: 22px;
  color: #737171bf;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.title-white {
  margin-top: 31px;
  color: #ffffff;
  select {
    height: 56px;
  }
}

.subtitle-white {
  margin-top: 34px;
  letter-spacing: 2px;
  color: #ffffff;
}

.blue-form {
  width: 100%;
  padding: 0 16px;
  padding-bottom: 15px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.blue-form label {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.blue-form input {
  width: 510px;
  padding: 27px 10px;
  margin-top: 6px;
  color: rgba(0, 98, 255, 0.6);
  background: rgba(255, 255, 255, 0.7) !important;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
}

.blue-title {
  margin-top: 16px;
  color: #0062FF;
}

.bot-form form {
  margin-top: 33px;
}

.notification-form {
  margin-top: 11px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .form-group {
    margin-bottom: 2rem !important;
    width: 353px;
  }
}

.notification-form label {
  margin-top: 10px;
  font-size: 13px;
  color: #848484;
}

.notification-form .comedian-settings-input {
  width: 353px;
  height: 56px;
}

.extra-form {
  width: 100%;
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  .form-group {
    margin-bottom: 2rem !important;
    width: 565px;
  }
}

.worklog-form {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  .form-group {
    margin-bottom: 2rem !important;
    width: 266px;
  }
}

.worklog-form label {
  margin-top: 10px;
  font-size: 13px;
  color: #848484;
}

.worklog-form .comedian-settings-input {
  width: 270px;
  height: 56px;
}

.extra-form label {
  margin-top: 10px;
  font-size: 13px;
  color: #848484;
}

.extra-form .comedian-settings-input {
  width: 510px;
  height: 59px;
}

input[name=reporting_time]::-webkit-inner-spin-button {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* Remove default arrow */
  background-image: url('../../assets/images/expand_more.svg');   /* Add custom arrow */
  background-repeat: no-repeat;
  background-position: 95% 24px;
  cursor: pointer;
}

.custom-group-text {
  display: flex;
}

.custom-group-text .input-group-append {
  position: relative;
}

.custom-group-text .input-group-text {
  padding: 0;
  top: 10px;
  left: 30px;
  position: absolute;
  background: none;
  border: none;
}

.button__save {
  margin-top: 64px;
}

.button__save button {
  padding: 17px 90px;
  background: #0062FF;
  border-radius: 0;
}

.react-select__control {
  &:active {
    border-color: #000000 !important;
  }
}

@media screen and (max-width: 991px) {
  .blue-form input {
    width: 325px;
  }

  .notification-form {
    flex-wrap: wrap;
    .comedian-settings-input {
      width: 200px;
      height: 56px;
    }
  }

  .notification-form .form-group {
    width: 33.3333%;
  }

  .worklog-form {
    flex-wrap: wrap;
    .form-group {
      width: 49%;
    }
    .comedian-settings-input {
      width: 340px;
      height: 56px;
    }
  }
  .extra-form .form-group {
    width: 49%;
  }

  .extra-form .comedian-settings-input {
    height: 56px;
  }
}

@media screen and (max-width: 767px) {
  .blue-form input {
    width: 100%;
  }

  .notification-form {
    flex-wrap: wrap;
    .comedian-settings-input {
      width: 240px;
      height: 56px;
    }
  }

  .notification-form .form-group {
    width: 49%;
  }

  .worklog-form {
    flex-wrap: wrap;
    .form-group {
      width: 49%;
    }
    .comedian-settings-input {
      width: 100%;
      height: 56px;
    }
  }
  .extra-form .form-group {
    width: 49%;
  }

  .extra-form .comedian-settings-input {
    height: 56px;
  }
}

@media screen and (max-width: 450px) {
  .notification-form .form-group {
    width: 100%;
  }

  .worklog-form {
    flex-wrap: wrap;
    .form-group {
      width: 100%;
    }
    .comedian-settings-input {
      width: 100%;
      height: 56px;
    }
  }
  .extra-form .form-group {
    width: 100%;
  }

  .title-white {
    font-size: 40px;
  }

  .blue-form {
    flex-direction: column;
  }

  .blue-form input {
    width: 100%;
  }

  .notification-form {
    flex-direction: column;
  }

  .worklog-form {
    flex-direction: column;
    .comedian-settings-input {
      width: 100%;
      height: 56px;
    }
  }

  .extra-form {
    flex-direction: column;
  }

  .notification-form .comedian-settings-input {
    width: 100%;
    height: 56px;
  }
}

@media screen and (max-width: 380px) {
  .title-white {
    font-size: 32px;
  }

  .blue-form {
    flex-direction: column;
  }

  .blue-form input {
    width: 100%;
  }

  .notification-form {
    flex-direction: column;
  }

  .worklog-form {
    flex-direction: column;
    .comedian-settings-input {
      width: 100%;
      height: 56px;
    }
  }

  .extra-form {
    flex-direction: column;
  }

  .notification-form .comedian-settings-input {
    width: 100%;
    height: 56px;
  }
}

@media screen and (max-width: 320px) {
  .blue-form {
    flex-direction: column;
  }

  .blue-form input {
    width: 100%;
  }

  .notification-form {
    flex-direction: column;
  }

  .worklog-form {
    flex-direction: column;
    .comedian-settings-input {
      width: 100%;
      height: 56px;
    }
  }

  .extra-form {
    flex-direction: column;
  }

  .notification-form .comedian-settings-input {
    width: 100%;
    height: 56px;
  }
}
